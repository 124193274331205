import {
  automaticAccess,
  bombRenovation,
  intercom,
  secureGate,
} from "../assets/improvements-image";
import { InformativeCardProps } from "../types/InformativeCardProps";

export const proyectsCardContent: InformativeCardProps[] = [
  {
    image: intercom,
    title: "Citofonía inteligente",
  },
  {
    image: automaticAccess,
    title: "Acceso automático",
  },
  {
    image: secureGate,
    title: "Portón Antiportonazo",
  },
  {
    image: bombRenovation,
    title: "Renovación Bombas de Calor + Subsidio Metrogas",
  },
];
