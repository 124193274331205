import { Flex } from "@chakra-ui/react";
import BackgroundImageBanner from "../../components/molecules/BackgroundImageBanner";
import InformativeCardContainer from "../../components/organisms/InformativeCardContainer";
import MainHeader from "../../components/organisms/MainHeader";
import { energeticEfficiency } from "../../constants/energeticEfficiency";
import { proyectsCardContent } from "../../constants/proyectsCardContent";

const MainHeaderContent = {
  title: "Gastos Comunes Eficientes, Administración Transparente",
};

const ImprovementsScreen = () => {
  return (
    <Flex flexDirection={"column"}>
      <MainHeader
        classNameProp={"improvements-hero-section"}
        title={MainHeaderContent.title}
      />

      <InformativeCardContainer
        headingText={"Proyectos Realizados"}
        description={
          "Descubre cómo hemos transformado comunidades con proyectos innovadores, desde modernización de espacios hasta mejoras en eficiencia energética. Nuestros proyectos no solo optimizan el funcionamiento de los edificios, sino que también elevan la calidad de vida de sus residentes."
        }
        cardContent={proyectsCardContent}
        maxWith="1448px"
        withPhoto
      />
      <BackgroundImageBanner
        title={"Gastos Comunes Eficientes, Administración Transparente"}
        buttonText={"Solicita tu propuesta"}
        to={"https://forms.gle/ynBerfiVpTfy9HrQ8"}
      />

      <InformativeCardContainer
        headingText={"Eficiencia Energética"}
        description={
          "Optimiza el consumo energético de tu comunidad con nuestras soluciones ecológicas. Desde bombas de calor eficientes hasta paneles fotovoltaicos, garantizamos una reducción en costos y un impacto positivo en el medioambiente. ¡Haz tu edificio más verde y eficiente con CF Administraciones!"
        }
        cardContent={energeticEfficiency}
        maxWith="1448px"
        withPhoto
      />
    </Flex>
  );
};
export default ImprovementsScreen;
