import { aboutCompany, aboutWorkingPhilosopshy } from "../assets/about-images";
import { InformativeSectionProps } from "../types/InformativeSectionProps";

export const AboutSectionContent: InformativeSectionProps[] = [
  {
    title: "La empresa",
    description:
      "CF nace en 2021 como una empresa de asesoría inmobiliaria. Hoy en día,nos hemos expandido hacia la administración de condominios, con todos nuestros miembros certificados por el Minvu en el Registro Nacional de Administradores. Desde nuestros inicios, nos enorgullece contar con cero reclamos, reflejando nuestro compromiso con la excelencia y la satisfacción de nuestros clientes.",
    image: aboutCompany,
  },
  {
    title: "Filosofía de Trabajo",
    description:
      "En CF Administraciones, nuestra filosofía de trabajo se basa en la transparencia, la eficiencia y el compromiso con la calidad. Creemos en la importancia de una gestión proactiva que no solo resuelva problemas, sino que anticipe necesidades, garantizando el bienestar y la satisfacción de las comunidades que administramos. Nos esforzamos por construir relaciones de confianza a largo plazo, actuando siempre con integridad y responsabilidad en cada acción que emprendemos.",
    image: aboutWorkingPhilosopshy,
  },
  {
    title: "Misión",
    description:
      "En CF Administraciones, nuestra misión es proporcionar una gestión integral y de calidad a las comunidades que administramos. Nos dedicamos a optimizar los recursos y mejorar el bienestar de cada comunidad, asegurando que cada decisión tomada refleje nuestro compromiso con la transparencia, la eficiencia y el servicio personalizado. Nuestro objetivo es ser un aliado confiable para las comunidades, abordando sus necesidades con soluciones efectivas y sostenibles.",
  },
  {
    title: "Visión",
    description:
      "Nuestra visión es ser reconocidos como líderes en la administración de comunidades, destacándonos por nuestra capacidad para anticipar y resolver desafíos de manera proactiva. Aspiramos a establecer un nuevo estándar en el sector, donde la excelencia en la gestión y el respeto por el medio ambiente vayan de la mano, contribuyendo a la creación de comunidades más eficientes, seguras y sostenibles a largo plazo.",
  },
  {
    title: "Equipo",
    description:
      "Nuestro equipo está compuesto por profesionales certificados y altamente capacitados, comprometidos con la excelencia en la gestión de comunidades. Cada miembro aporta su experiencia y dedicación para asegurar que cada comunidad que administramos alcance su máximo potencial.",
  },
  {
    title: "Comunidades",
    description:
      "Descubre algunas de las comunidades que confían en nosotros para su administración, respaldadas por nuestro compromiso con la calidad y la eficiencia.",
  },
];
