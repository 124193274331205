import {
  specializedAdvice,
  supportStaff,
  legalSupport,
  costReduction,
  managementReports,
  environmentallyFriendly,
} from "../assets/service-icons";
import { InformativeCardProps } from "../types/InformativeCardProps";

export const serviceCardContent: InformativeCardProps[] = [
  {
    id: "1",
    title: "Asesoría Especializada",
    description:
      "Equipo de profesionales especialistas en administración, consultoría y gestión de edificios comerciales y residenciales.",
    image: specializedAdvice,
  },
  {
    id: "2",
    title: "Staff de apoyo",
    description:
      "Trabajamos con empresas certificadas en mantención de ascensores, electricidad, hidráulicos y servicios generales.",
    image: supportStaff,
  },
  {
    id: "3",
    title: "Apoyo Legal",
    description:
      "Trabajamos con un staff de abogados especialistas en derecho laboral y gestión inmobiliaria.",
    image: legalSupport,
  },
  {
    id: "4",
    title: "Reducción de Costos",
    description:
      "Buscamos los mejores proveedores para asegurar una excelente relación costo-beneficio, privilegiando la calidad, para rentabilizar su inversión.",
    image: costReduction,
  },
  {
    id: "5",
    title: "Informes de Gestión",
    description:
      "Transparencia y cuentas claras en gastos comunes y gestión inmobiliaria.",
    image: managementReports,
  },
  {
    id: "6",
    title: "Amigables con el medio ambiente",
    description:
      "Buscamos reducir la huella de carbono, utilizando insumos que procuren la eficiencia energética y mejoren la calidad de vida de los residentes.",
    image: environmentallyFriendly,
  },
];
