import { ModalCardProps } from "../types/ModalCardProps";

export const teamCardContent: ModalCardProps[] = [
  {
    title: "Felipe Mesa",
    description:
      "Ingeniero comercial, MBA. Administrador por registro nacional de administradores.",
  },
  {
    title: "Ulises Matamala",
    description:
      "Ingeniero en administración. Administrador por registro nacional de administradores.",
  },
  {
    title: "Mely Montiel",
    description:
      "Licenciada en educación diferencial. Encargada de operaciones.",
  },
  {
    title: "Katherine Mondaca",
    description: "Contador general. Encargada de operaciones.",
  },
];
