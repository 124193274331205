import {
  administrationSoftware,
  capitalGain,
  certifiedSuppliers,
  clearBudgets,
  constantCommunication,
  costOptimization,
  legalCompliance,
} from "../assets/guarrantee-icons";
import { InformativeCardProps } from "../types/InformativeCardProps";

export const guaranteeCardContent: InformativeCardProps[] = [
  {
    image: legalCompliance,
    title: "Cumplimiento Legal",
    description:
      "Aseguramos que tu comunidad cumpla con toda la normativa vigente en copropiedad.",
  },
  {
    image: costOptimization,
    title: "Optimización de Gastos",
    description:
      "Reducimos tus costos comunes al máximo sin comprometer la calidad de servicio.",
  },
  {
    image: certifiedSuppliers,
    title: "Proveedores Certificados",
    description:
      "Trabajamos solo con proveedores 100% certificados y de confianza para tu tranquilidad.",
  },
  {
    image: clearBudgets,
    title: "Presupuestos Claros",
    description:
      "Generamos presupuestos detallados y ajustados a las necesidades específicas de tu copropiedad.",
  },
  {
    image: capitalGain,
    title: "Plusvalía Inmobiliaria",
    description:
      "Tomamos acciones para aumentar el valor de tu inversión inmobiliaria.",
  },
  {
    image: administrationSoftware,
    title: "Software Administración",
    description:
      "Ofrecemos un software que permite a los vecinos acceder a la información de la comunidad en tiempo real.",
  },
  {
    image: constantCommunication,
    title: "Comunicación Constante",
    description:
      "Mantenemos una comunicación continua con el comité y la comunidad para resolver cualquier inquietud.",
  },
];
