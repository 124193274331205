import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Heading,
} from "@chakra-ui/react";

const accordionItems = [
  {
    title: "Microbarrio",
    content:
      "Un microbarrio es un grupo de viviendas que no necesariamente se rige por la ley de copropiedad. La administración eficiente puede transformar estos espacios en comunidades organizadas y seguras.",
  },
  {
    title: "Comunidad Mixta",
    content:
      "Una comunidad mixta combina residencias y comercios, y está sujeta a la ley de copropiedad 21.442. Una buena administración asegura el equilibrio entre ambas actividades, manteniendo la armonía y el valor del entorno.",
  },
  {
    title: "Comunidad Habitacional",
    content:
      "Una comunidad habitacional está compuesta exclusivamente por viviendas y está regulada por la ley de copropiedad 21.442. Con nuestra administración, garantizamos que tu comunidad esté bien cuidada y conforme a la normativa.",
  },
  {
    title: "Residencia universitaria",
    content:
      "Una residencia universitaria alberga estudiantes y está diseñada para ofrecer un entorno de convivencia adecuado para el estudio y la vida estudiantil. Una buena administración puede mejorar la calidad de vida de los estudiantes y el mantenimiento correcto de los espacios comunes.",
  },
];

const AccordionContainer = () => {
  return (
    <Accordion allowToggle w={["80vw", "80vw", "40vw", "40vw"]}>
      {accordionItems.map((item, index) => (
        <AccordionItem key={index}>
          <Heading>
            <AccordionButton>
              <Box as="span" flex="1" textAlign="left">
                <Heading as="h6" size="md">
                  {item.title}
                </Heading>
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </Heading>
          <AccordionPanel width={"100%"} pb={4}>
            {item.content}
          </AccordionPanel>
        </AccordionItem>
      ))}
    </Accordion>
  );
};
export default AccordionContainer;
