import { Link } from "@chakra-ui/react";
import { MenuProps } from "../../../types/MenuProps";

const MenuItem: React.FC<MenuProps> = ({
  children,
  isLast,
  to = "/",
  isExternal,
  ...rest
}) => {
  // TODO active menu link
  return (
    <Link variant={"menu"} href={to} isExternal={isExternal} {...rest}>
      {children}
    </Link>
  );
};

export default MenuItem;
