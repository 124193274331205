import { Center, Flex, Heading, Image, Stack, Text } from "@chakra-ui/react";
import { InformativeCardProps } from "../../../types/InformativeCardProps";

const IconCard = ({ image, title, description }: InformativeCardProps) => {
  return (
    <Flex gap={12} flexDirection={"column"} w={"326px"} p={6}>
      {image && (
        <Center>
          <Image src={image} alt={title} />
        </Center>
      )}
      <Stack>
        <Heading fontSize="sm" textAlign={"center"} color={"text.primary"}>
          {title}
        </Heading>
        <Text color={"text.primary"}>{description}</Text>
      </Stack>
    </Flex>
  );
};
export default IconCard;
