import { extendTheme } from "@chakra-ui/react";
import Button from "./components/atoms/button";
import Link from "./components/atoms/link";
import "@fontsource/poppins";
import "@fontsource/roboto";

const customTheme = extendTheme({
  colors: {
    brand: {
      primary: "hsl(210, 85%, 15%)",
      secondary: "hsl(45, 95%, 48%)",
      accent: "hsl(275, 100%, 28%)",
    },
    primary: {
      100: "hsl(210, 85%, 94%)",
      200: "hsl(210, 85%, 82%)",
      300: "hsl(210, 85%, 70%)",
      400: "hsl(210, 85%, 56%)",
      500: "hsl(210, 85%, 15%)",
      600: "hsl(210, 85%, 13%)",
      700: "hsl(210, 85%, 10%)",
      800: "hsl(210, 85%, 7%)",
      900: "hsl(210, 85%, 5%)",
    },
    secondary: {
      100: "hsl(45, 90%, 90%)",
      200: "hsl(45, 90%, 78%)",
      300: "hsl(45, 90%, 66%)",
      400: "hsl(45, 90%, 52%)",
      500: "hsl(45, 95%, 48%)",
      600: "hsl(45, 100%, 43%)",
      700: "hsl(45, 90%, 28%)",
      800: "hsl(45, 90%, 22%)",
      900: "hsl(45, 90%, 16%)",
    },
    accent: {
      100: "hsl(275, 100%, 92%)",
      200: "hsl(275, 100%, 78%)",
      300: "hsl(275, 100%, 66%)",
      400: "hsl(275, 100%, 52%)",
      500: "hsl(275, 100%, 28%)",
      600: "hsl(275, 100%, 24%)",
      700: "hsl(275, 100%, 18%)",
      800: "hsl(275, 100%, 14%)",
      900: "hsl(275, 100%, 10%)",
    },
    gray: {
      100: "hsl(210, 33%, 95%)",
      200: "hsl(240, 15%, 70%)",
      300: "hsl(240, 14%, 62%)",
      400: "hsl(240, 14%, 28%)",
      500: "hsl(240, 33%, 18%)",
    },
    surface: {
      primary: "gray.100",
      secondary: "hsl(210, 85%, 15%)",
      tertiary: "secondary.default",
      invert: "gray.500",
      overlay: "accent.default",
      hover: "accent.100",
    },
    text: {
      primary: "hsl(240, 33%, 18%)",
      invert: "hsl(210, 33%, 95%)",
      inactive: "gray.300",
      hoverPrimary: "accent.default",
      hoverAccent: "primary.100",
    },
  },
  fonts: {
    body: "Roboto, system-ui, Avenir, Helvetica, Arial, sans-serif",
    heading: "Poppins, system-ui, Avenir, Helvetica, Arial, sans-serif",
    link: "Poppins, system-ui, Avenir, Helvetica, Arial, sans-serif",
  },
  fontWeights: {
    normal: 400,
    semibold: 600,
    bold: 700,
  },
  lineHeights: {
    normal: "130%",
  },
  components: {
    Button,
    Link,
  },
});

export default customTheme;
