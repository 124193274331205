// theme.ts (tsx file with usage of StyleFunctions, see 4.)
import { defineStyleConfig } from "@chakra-ui/react";

const Button = defineStyleConfig({
  baseStyle: {
    fontWeight: "semibold",
    borderRadius: "base",
    fontFamily: "heading",
    // _hover: { bg: "secondary.500", transform: "scale(1.03)" },
  },
  // 2. We can add a new button size or extend existing
  sizes: {
    sm: {
      fontSize: "sm",
      px: 6, // <-- px is short for paddingLeft and paddingRight
      py: 2, // <-- py is short for paddingTop and paddingBottom
    },
    lg: {
      fontSize: "lg",
      px: 8, // <-- these values are tokens from the design system
      py: 4, // <-- these values are tokens from the design system
    },
  },

  variants: {
    outline: {
      border: "2px solid",
      borderColor: "secondary.500",
      color: "gray.100",
      _hover: { bg: "secondary.500", transform: "scale(1.03)" },
      _active: { bg: "secondary.600", transform: "scale(1)" },
    },
    "primary-solid": {
      bg: "primary.500",
      color: "gray.100",
      _hover: { transform: "scale(1.03)" },
      _active: { bg: "primary.600", transform: "scale(1)" },
    },
    "secondary-solid": {
      bg: "secondary.500",
      color: "gray.100",
      _hover: { transform: "scale(1.03)" },
      _active: { bg: "secondary.600", transform: "scale(1)" },
    },
  },
  // 6. We can overwrite defaultProps
  defaultProps: {
    size: "lg", // default is md
    variant: "secondary-solid", // default is solid
    colorScheme: "secondary", // default is gray
  },
});

export default Button;
