import { Center, Flex, Heading, Image, Text } from "@chakra-ui/react";
import image from "../../../assets/home-images/personalized-service.jpg";
import AccordionContainer from "../../molecules/AccordionContainer";
import "./styles.css";

const PersonalizedService = () => {
  return (
    <Flex
      direction={"column"}
      bg={"primary.500"}
      flex="1"
      color={"gray.100"}
      align={"center"}
      py={10}
    >
      <Heading size={"2xl"} py={8} textAlign={"center"}>
        Identifica Tu Comunidad
      </Heading>

      <Center maxW="80%">
        <Text fontSize="xl" color={"gray.100"} align={"center"}>
          Descubre el tipo de comunidad en la que vives y obtén soluciones
          personalizadas para mejorar la gestión y calidad de tu entorno.
        </Text>
      </Center>
      <Flex direction={["column", "column", "row", "row"]} p={8} gap={8}>
        <Flex flex="1">
          <Image src={image} alt="Edificio" />
        </Flex>
        <Flex flex="1">
          <AccordionContainer />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default PersonalizedService;
