import {
  Card,
  CardBody,
  CardFooter,
  Heading,
  Stack,
  Text,
} from "@chakra-ui/react";

export interface ModalCardProps {
  title: string;
  description?: string;
}

const ModalCardNoImage = ({ title, description }: ModalCardProps) => {
  return (
    <Card
      maxW="lg"
      bgColor={"secondary.100"}
      direction={{ base: "column", sm: "row" }}
    >
      <CardBody flex={1} alignContent={"center"}>
        <Stack>
          <Heading fontSize="sm" textAlign={"center"} color={"text.primary"}>
            {title}
          </Heading>
        </Stack>
      </CardBody>
      <CardFooter flex={2} flexDirection={"column"} gap={2}>
        <Text color={"text.primary"}>{description}</Text>
        {/* <Button size="sm" variant="link" colorScheme="primary">
          Más info
        </Button> */}
      </CardFooter>
    </Card>
  );
};
export default ModalCardNoImage;
