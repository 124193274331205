import {
  bulnes,
  pucara,
  salvador,
  terceraAvenida,
  urbanSantaigo,
} from "../assets/about-images";
import { InformativeCardProps } from "../types/InformativeCardProps";

export const communityCardContent: InformativeCardProps[] = [
  {
    image: terceraAvenida,
    title: "Tercera Avenida, San Miguel",
  },
  {
    image: urbanSantaigo,
    title: "Urban Santiago, Santiago",
  },
  {
    image: pucara,
    title: "Pucará, San Bernardo",
  },
  {
    image: bulnes,
    title: "Edificio Bulnes, Santiago",
  },
  {
    image: salvador,
    title: "Cité Salvador, Providencia",
  },
];
