import { Box } from "@chakra-ui/react";
import customTheme from "../../../theme/theme";

const { colors } = customTheme;

export default function Logo(props: any) {
  return (
    <Box {...props}>
      <svg
        width="72"
        height="73"
        viewBox="0 0 72 73"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M36.0011 69.4229C54.1227 69.4229 68.8131 54.7325 68.8131 36.6109C68.8131 18.4893 54.1227 3.79883 36.0011 3.79883C17.8795 3.79883 3.18903 18.4893 3.18903 36.6109C3.18903 54.7325 17.8795 69.4229 36.0011 69.4229Z"
          fill="white"
        />
        <path
          d="M56.3861 21.2988L57.3382 31.2328C56.3017 31.3869 56.1159 31.2834 56.0864 30.2829C56.0464 27.9116 55.2166 25.6215 53.7285 23.7749C52.9348 22.787 51.987 21.9807 50.503 22.0461C48.2591 22.1432 46.0068 22.0714 43.6784 22.0714V37.004H49.2998V32.4867H51.1279V42.4987H49.3272V37.9645H43.6806V51.1851L45.7155 51.2801C45.4031 51.8902 39.9865 52.1245 37.9621 51.7129L37.8903 51.4089L39.9548 51.1324V22.1812C39.2983 22.0968 38.6249 22.0187 37.9621 21.8983C37.9427 21.699 37.9427 21.4982 37.9621 21.2988H56.3861Z"
          fill={colors.brand.primary}
        />
        <path
          d="M31.5006 21.2428L32.4526 31.2507C31.6758 31.409 31.2558 31.4406 31.1291 30.4696C30.7724 27.7677 30.004 25.0931 28.0451 23.1616C25.5774 20.7278 22.3899 20.6982 19.9539 23.9385C18.6663 25.6525 18.3243 27.6748 18.2863 29.7118C18.2033 34.2475 18.2033 38.7852 18.2863 43.3251C18.3243 45.4761 18.8267 47.5195 20.0764 49.3897C22.2253 52.6047 26.4471 52.2627 28.6045 49.3518C30.0926 47.3443 30.823 45.0159 31.1523 42.5609C31.2473 41.8517 31.5745 41.5751 32.4442 41.801L31.5133 51.8236C31.108 51.8236 30.6985 51.7983 30.2932 51.8236C28.6045 51.9482 26.9031 52.1276 25.2059 52.2184C22.8987 52.3408 20.7033 51.9545 18.8731 50.4072C15.9179 47.9058 14.6809 44.6402 14.6851 40.8363C14.6851 37.7058 14.5922 34.5711 14.7125 31.4449C14.7923 28.4756 15.9666 25.6406 18.0098 23.4846C20.2347 21.0972 23.076 20.8101 26.0714 21.0423C26.6223 21.0845 27.169 21.2112 27.72 21.2238C28.957 21.2555 30.1855 21.2428 31.5006 21.2428Z"
          fill={colors.brand.primary}
        />
        <path
          d="M36.0011 70.5572C54.7487 70.5572 69.9467 55.3592 69.9467 36.6116C69.9467 17.864 54.7487 2.66602 36.0011 2.66602C17.2534 2.66602 2.05548 17.864 2.05548 36.6116C2.05548 55.3592 17.2534 70.5572 36.0011 70.5572Z"
          stroke={colors.brand.primary}
          stroke-width="3"
          stroke-miterlimit="10"
        />
      </svg>
    </Box>
  );
}
