import { Button, Center, Flex, Heading, Text } from "@chakra-ui/react";
import "./styles.css";
import { BannerProps } from "../../../types/BannerProps";

const BackgroundImageBanner = ({
  title,
  buttonText,
  to,
  text,
  classNameProp,
  variant,
}: BannerProps) => {
  const handleButtonClick = () => {
    window.open(to, "_blank");
  };
  return (
    <Flex
      className={classNameProp ? classNameProp : "banner-section"}
      direction={"column"}
      align={"center"}
      justify={"center"}
      flex="1"
      py={10}
    >
      <Center
        flexDir={"column"}
        gap={4}
        py={14}
        w={["90vw", "85vw", "70vw", "70vw"]}
      >
        <Heading size={"2xl"} color={"gray.100"} textAlign={"center"} px={6}>
          {title}
        </Heading>
        {text && (
          <Text fontSize={"xl"} color={"text.invert"} textAlign={"center"}>
            {text}
          </Text>
        )}
        <Button
          variant={variant || "primary-solid"}
          onClick={handleButtonClick}
        >
          {buttonText}
        </Button>
      </Center>
    </Flex>
  );
};

export default BackgroundImageBanner;
