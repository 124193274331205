import { createBrowserRouter, RouterProvider } from "react-router-dom";
import AboutScreen from "../pages/AboutScreen";
import ContactScreen from "../pages/ContactScreen";
import HomeScreen from "../pages/HomeScreen";
import ImprovementsScreen from "../pages/ImprovementsScreen";

const router = createBrowserRouter([
  {
    path: "/",
    element: <HomeScreen />,
  },
  {
    path: "/nosotros",
    element: <AboutScreen />,
  },
  {
    path: "/contacto",
    element: <ContactScreen />,
  },
  {
    path: "/mejoras",
    element: <ImprovementsScreen />,
  },
  {
    path: "*",
    element: <HomeScreen />,
  },
]);

const Routes = () => <RouterProvider router={router} />;
export default Routes;
