import { Flex, Link, Text } from "@chakra-ui/react";
import Logo from "../../atoms/Logo";

const Footer = () => {
  return (
    <Flex
      bg={"surface.secondary"}
      flex={1}
      flexDir={"column"}
      px={6}
      py={10}
      gap={16}
    >
      <Flex direction={["column", "column", "row", "row"]} align={"center"}>
        <Logo flex={1} align={"center"} />
        <Flex flexDir={"column"} flex={1}>
          <Link href="https://forms.gle/NJcGDgqV2Jdp3ZLw9" isExternal>
            → Trabaja con nosotros
          </Link>
          <Link href="https://forms.gle/7rA1ixpytr6eSon89" isExternal>
            → ¿Eres Proveedor?
          </Link>
          <Link href="https://forms.gle/bG5KTv7jeA7R72tP6" isExternal>
            → ¿Eres vecino y tienes un problema?
          </Link>
        </Flex>
      </Flex>
      <Text fontSize={"md"} color={"text.invert"} align={"center"}>
        CF Administraciones <Text as="b">© TODOS LOS DERECHOS RESERVADOS</Text>
      </Text>
    </Flex>
  );
};

export default Footer;
