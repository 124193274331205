import { Button, Center, Flex, Heading, Text } from "@chakra-ui/react";
import IconCard from "../../molecules/IconCard";
import { InformativeCardProps } from "../../../types/InformativeCardProps";
import PhotoCard from "../../molecules/PhotoCard";

interface InformativeCardContainerProps {
  headingText: string;
  cardContent: InformativeCardProps[];
  description?: string;
  withIcon?: boolean;
  withPhoto?: boolean;
  maxWith?: string;
  withButton?: boolean;
}

const InformativeCardContainer = ({
  headingText,
  description,
  cardContent,
  withIcon,
  withPhoto,
  maxWith,
  withButton,
}: InformativeCardContainerProps) => {
  const handleButtonClick = () => {
    const url = "https://forms.gle/ynBerfiVpTfy9HrQ8";
    window.open(url, "_blank");
  };
  return (
    <Flex
      flexDirection={"column"}
      gap={12}
      align={"center"}
      justify={"center"}
      flex="1"
      py={20}
    >
      <Center>
        <Heading size={"xl"} color={"gray.500"} textAlign={"center"}>
          {headingText}
        </Heading>
      </Center>
      {description && (
        <Text color={"gray.500"} textAlign={"center"} maxW={"80vw"}>
          {description}
        </Text>
      )}
      {withIcon && (
        <Flex
          wrap={"wrap"}
          gap={12}
          justify={"center"}
          align={"center"}
          maxW={maxWith}
        >
          {cardContent.map((item: InformativeCardProps) => (
            <IconCard
              key={item.id}
              image={item.image}
              title={item.title}
              description={item.description}
            />
          ))}
        </Flex>
      )}
      {withPhoto && (
        <Flex
          wrap={"wrap"}
          gap={12}
          justify={"center"}
          align={"center"}
          maxW={maxWith}
        >
          {cardContent.map((item: InformativeCardProps) => (
            <PhotoCard
              key={item.id}
              image={item.image}
              title={item.title}
              description={item.description}
            />
          ))}
        </Flex>
      )}
      {withButton && <Button onClick={handleButtonClick}>Conócenos</Button>}
    </Flex>
  );
};

export default InformativeCardContainer;
