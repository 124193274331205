import { Button, Center, Flex, Heading } from "@chakra-ui/react";
import NavBar from "../Navbar";
import "./styles.css";

interface MainHeaderProps {
  classNameProp: string | undefined;
  title: string;
  withButton?: boolean;
}

const MainHeader = ({ withButton, classNameProp, title }: MainHeaderProps) => {
  const handleButtonClick = () => {
    const url = "https://forms.gle/ynBerfiVpTfy9HrQ8";
    window.open(url, "_blank");
  };
  return (
    <Flex
      className={classNameProp}
      direction={"column"}
      align={"center"}
      justify={"center"}
      flex="1"
      pb={10}
    >
      <NavBar />

      <Center flexDir={"column"} gap={8} py={20}>
        <Heading size={"2xl"} color={"gray.100"} textAlign={"center"} px={6}>
          {title}
        </Heading>
        {withButton && (
          <Button onClick={handleButtonClick}>Solicita tu propuesta</Button>
        )}
      </Center>
    </Flex>
  );
};

export default MainHeader;
