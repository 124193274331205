import { bomb, carCharger, cleanEnergy } from "../assets/improvements-image";
import { InformativeCardProps } from "../types/InformativeCardProps";

export const energeticEfficiency: InformativeCardProps[] = [
  {
    image: bomb,
    title: "Bombas de calor",
  },
  {
    image: carCharger,
    title: "Cargador vehículos eléctricos",
  },
  {
    image: cleanEnergy,
    title: "Energía renovable  en espacios comunes",
  },
];
