import { Divider } from "@chakra-ui/react";
import BackgroundImageBanner from "../../components/molecules/BackgroundImageBanner";
import MainHeader from "../../components/organisms/MainHeader";

const MainHeaderContent = {
  title: "Estás en el lugar correcto para comunicarte con nosotros. ",
};
const Strings = {
  CONTACT_FORM: "https://forms.gle/ynBerfiVpTfy9HrQ8",
  SUPPLIERS_FORM: "https://forms.gle/7rA1ixpytr6eSon89",
  TICKETS_FORM: "https://forms.gle/bG5KTv7jeA7R72tP6",
  REFERRALS_FORM: "https://forms.gle/tp9QvkKHxoe9fCkS9",
  WORK_WITH_US_FORM: "https://forms.gle/NJcGDgqV2Jdp3ZLw9",
};
const ContactScreen = () => {
  return (
    <div>
      <MainHeader
        classNameProp={"contacts-hero-section"}
        title={MainHeaderContent.title}
      />
      <BackgroundImageBanner
        classNameProp="contact-banner-section"
        variant="secondary-solid"
        title={"¡Hablemos! \n Cuéntanos qué necesitas"}
        text="Estamos aquí para ayudarte a mejorar la gestión de tu comunidad. Descubre cómo podemos optimizar la administración y reducir los gastos. Nos dedicamos a organizar, optimizar y orientar la gestión de tu inmueble, preparando una propuesta personalizada que satisfaga todas tus necesidades.  ¡Contáctanos y comencemos a trabajar juntos en un futuro mejor para tu comunidad!"
        buttonText={"Solicita tu propuesta"}
        to={Strings.CONTACT_FORM}
      />
      <Divider />
      <BackgroundImageBanner
        classNameProp="contact-banner-section"
        variant="secondary-solid"
        title={"¿Eres vecino y tienes un problema?"}
        buttonText={"TICKETS"}
        to={Strings.TICKETS_FORM}
      />
      <Divider />
      <BackgroundImageBanner
        classNameProp="contact-banner-section"
        variant="secondary-solid"
        title={"¡Recomiéndanos en tu comunidad!"}
        buttonText={"Refierenos y gana"}
        text={"Te puedes llevar una giftcard"}
        to={Strings.REFERRALS_FORM}
      />
      <Divider />
      <BackgroundImageBanner
        classNameProp="contact-banner-section"
        variant="secondary-solid"
        title={"¿Eres Proveedor?"}
        text={
          "Si eres proveedor de servicios o productos y quieres colaborar con nosotros, en CF Administraciones nos interesa trabajar con profesionales confiables y de calidad. Contáctanos y exploremos oportunidades juntos."
        }
        buttonText={"Contáctanos"}
        to={Strings.SUPPLIERS_FORM}
      />
      <Divider />
      <BackgroundImageBanner
        classNameProp="contact-banner-section"
        variant="secondary-solid"
        title={"Trabaja con Nosotros"}
        text={
          "En CF Administraciones, valoramos el talento comprometido. Si deseas formar parte de un equipo dedicado a mejorar la calidad de vida en nuestras comunidades, envíanos tu información. ¡Estamos siempre en busca de nuevos talentos!"
        }
        buttonText={"Contáctanos"}
        to={Strings.WORK_WITH_US_FORM}
      />
    </div>
  );
};
export default ContactScreen;
