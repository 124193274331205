import { Flex } from "@chakra-ui/react";
import BackgroundImageBanner from "../../components/molecules/BackgroundImageBanner";
import InformativeCardContainer from "../../components/organisms/InformativeCardContainer";
import MainHeader from "../../components/organisms/MainHeader";
import PersonalizedService from "../../components/organisms/PersonalizedService";
import { guaranteeCardContent } from "../../constants/guaranteeCardContent";
import { serviceCardContent } from "../../constants/serviceCardContent";

const MainHeaderContent = {
  title: "Gastos Comunes Eficientes, Administración Transparente",
};
const HomeScreen = () => {
  return (
    <Flex flexDirection={"column"}>
      <MainHeader
        withButton
        classNameProp={"home-hero-section"}
        title={MainHeaderContent.title}
      />
      <PersonalizedService />
      <BackgroundImageBanner
        title={"¡Recomiéndanos en tu comunidad!"}
        buttonText={"Refierenos y gana"}
        text={"Te puedes llevar una giftcard"}
        to={"https://forms.gle/tp9QvkKHxoe9fCkS9"}
      />
      <InformativeCardContainer
        headingText={"¿Qué necesitas?"}
        cardContent={serviceCardContent}
        maxWith="1447px"
        withIcon
      />
      <BackgroundImageBanner
        title={"¿Cómo diagnosticamos?"}
        text={
          "Diagnóstico Completo para tu Comunidad:\nIdentificamos Problemas, Proponemos Soluciones y Aseguramos el Cumplimiento Legal"
        }
        buttonText={"Contáctanos"}
        to={"https://forms.gle/ynBerfiVpTfy9HrQ8"}
      />
      <InformativeCardContainer
        headingText={"Garantizamos nuestra gestión"}
        cardContent={guaranteeCardContent}
        maxWith="1448px"
        withIcon
        withButton
      />
    </Flex>
  );
};

export default HomeScreen;
